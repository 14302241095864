
  import { Component, Watch } from 'vue-property-decorator'
  import Cell from './cell'
  import dayjs from 'dayjs'

@Component
  export default class DailyAccountAlert extends Cell {
  displayAlert = false

  @Watch('systemSchedule', { immediate: true, deep: true })
  onSystemScheduleChanged (value) {
    const previousDate = dayjs(this.item.previousRegistrationDate)
    const currentDate = dayjs()
    const diff = currentDate.diff(previousDate, 'day')

    if (diff > 1) {
      // Mapeo de nombres de días a su formato en tu schedule
      const dayMap = {
        lunes: 'Lunes',
        martes: 'Martes',
        miércoles: 'Miércoles',
        jueves: 'Jueves',
        viernes: 'Viernes',
        sábado: 'Sabado',
        domingo: 'Domingo',
      }

      // Iterar sobre cada día entre previousDate y currentDate
      for (let i = 1; i < diff; i++) {
        const dateToCheck = previousDate.add(i, 'day')
        const dayName = dateToCheck.locale('es').format('dddd').toLowerCase()
        const dayFormatted = dayMap[dayName]

        // Iterar sobre los schedules para encontrar coincidencias
        value.forEach(scheduleItem => {
          const scheduleDays = scheduleItem.day // Ejemplo: "Lunes - Viernes", "Sabado", "Domingo"
          let daysArray = []

          if (scheduleDays.includes('-')) {
            // Manejar rangos como "Lunes - Viernes"
            const [startDay, endDay] = scheduleDays.split(' - ')
            const allDays = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo']
            const startIndex = allDays.indexOf(startDay)
            const endIndex = allDays.indexOf(endDay)
            if (startIndex !== -1 && endIndex !== -1) {
              if (startIndex <= endIndex) {
                daysArray = allDays.slice(startIndex, endIndex + 1)
              } else {
                // Manejar semanas que envuelven (por ejemplo, "Viernes - Lunes")
                daysArray = allDays.slice(startIndex).concat(allDays.slice(0, endIndex + 1))
              }
            }
          } else {
            // Día individual
            daysArray = [scheduleDays]
          }

          // Verificar si dayFormatted coincide con alguno de los días en daysArray
          if (daysArray.includes(dayFormatted) && scheduleItem.start) {
            this.displayAlert = true
          }
        })
      }
    }
  }
  }
